"use strict";

/**
 * Created by Thiago Lima on 20/02/2020.
 */

(function () {
  'use strict';

  function dependencies(window) {
    return {
      generalLanguage: window.generalLanguage
    };
  }
  var _dependencies = dependencies(window),
    generalLanguage = _dependencies.generalLanguage;
  var en = {
    generalLanguage: generalLanguage
  };
  window.moduleExport({
    en: en
  }, typeof module !== 'undefined' && module);
})();